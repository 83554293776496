// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-littleplusbig-gatsby-theme-legals-prismic-src-templates-legal-js": () => import("./../node_modules/@littleplusbig/gatsby-theme-legals-prismic/src/templates/legal.js" /* webpackChunkName: "component---node-modules-littleplusbig-gatsby-theme-legals-prismic-src-templates-legal-js" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-homepage-js": () => import("./../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

